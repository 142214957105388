import type { JSX } from "solid-js";
import { createSignal, Show } from "solid-js";
import { DisplayUploadGrid, UploadRequest } from "./displayUpload";

function createSubmit(file: File): UploadRequest {
	const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
	const request = fetch("https://kprobe.v6.rocks/cgi/binup", {
		method: "POST",
		mode: "cors",
		headers: {
			Extension: extension,
			"File-name": file.name
		},
		body: file
	});

	let urlPromise = new Promise<string>((resolve, reject) =>
		request.then((res) => {
			if (res.ok)
				res.text().then(resolve, reject);
			else
				// @ts-ignore
				reject(new Error("Response is not OK", {cause: res}));
		}).catch(reject)
	);
	return {name: file.name, size: file.size, urlPromise};
}

function App() {
	const [file, setFile] = createSignal<File | undefined>(undefined);
	const [uploadList, setUploadList] = createSignal<UploadRequest[]>([], {equals: false});
	const onFile: JSX.EventHandlerUnion<HTMLInputElement, Event> = (event) => {
		let new_file = event.currentTarget.files?.item(0) ?? undefined;
		setFile(new_file);
	}

	function submit(event: SubmitEvent) {
		event.preventDefault();
		let current_file = file();
		if (!current_file) return;
		let request = createSubmit(current_file);
		let list = uploadList();
		list.push(request);
		setUploadList(list);
	}

	return (<>
		<h1>Upload files to https://kprobe.v6.rocks</h1>
		<form onSubmit={submit}>
			<label>File: <input type="file" onChange={onFile} autocomplete="off" required/></label>
			<input type="submit"/>
		</form>
		<div>
			Info:
			<Show when={file() != undefined}>
				<span>Name: {file()?.name} </span>
				<span>Size: {file()?.size} </span>
				<span>Type: {file()?.type}</span>
			</Show>
		</div>
		<DisplayUploadGrid list={uploadList}/>
	</>);
}

export default App;