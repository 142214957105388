import "./displayUpload.css";
import type { Accessor, VoidProps } from "solid-js";
import { createSignal, For, Match, Switch } from "solid-js";

export interface UploadRequest {
	name: string,
	size: number,
	urlPromise: Promise<string>,
}

function DisplayUploadGrid(props: VoidProps<{ list: Accessor<UploadRequest[]> }>) {
	return (<table class="upload_grid">
		<thead>
		<tr>
			<td>Name</td>
			<td class="size">Size</td>
			<td>Link</td>
		</tr>
		</thead>
		<For each={props.list()}>
			{(item) => <DisplayUpload item={item}/>}
		</For>
	</table>);
}

export function DisplayUpload(props: VoidProps<{ item: UploadRequest }>) {
	const [url, setUrl] = createSignal<string | undefined>(undefined);
	const [failed, setFailed] = createSignal(false);
	props.item.urlPromise.then(setUrl).catch((err) => {
		console.log("Error uploading file", err);
		setFailed(true);
	});
	return (<tr class="upload_entry">
		<td class="name" title={props.item.name}>{props.item.name}</td>
		<td class="size">{props.item.size}</td>
		<td class="link">
			<Switch fallback="Pending...">
				<Match when={url() != undefined}>
					<a target="_blank" href={url()}>{url()}</a>
				</Match>
				<Match when={failed()}>
					<span>Failed</span>
				</Match>
			</Switch>
		</td>
	</tr>);
}

export { DisplayUploadGrid };